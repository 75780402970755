'use strict'

###*
 # @ngdoc service
 # @name mundoLive.factory:LiveSubscriptionManager

 # @description

###
angular
  .module 'mundoLive'
  .factory 'LiveSubscriptionManager', [
    'MundoDefaultManager'
    '$q'
    'Restangular'
    (
      MundoDefaultManager
      $q
      Restangular
    ) ->
      Restangular.service('subscriptions')
  ]
